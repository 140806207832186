<template>
  <v-dialog v-model="isOpen" persistent max-width="400px">
    <v-overlay opacity="1">
      <v-card>
        <v-card-title>
          Reset Password Required
        </v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="doSubmit">
            <v-text-field outlined label="Password" v-model="password.first" :rules="inputs.rules.passwordRequired" type="password"></v-text-field>
            <v-text-field outlined label="Verify Password" v-model="password.second" :rules="inputs.rules.passwordRequired" type="password"></v-text-field>
            <v-btn type="submit" color="primary" block class="mt-4">Reset Password</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-overlay>
  </v-dialog>
</template>

<script>
export default {
  name: 'resetPassword',
  data: () => ({
    password: {
      first: null,
      second: null
    },
    isLoading: false,
    isOpen: false
  }),
  methods: {
    doOpen () {
      this.isOpen = true
    },
    doSubmit () {
      if (this.$refs.form.validate()) {
        if (this.password.first === this.password.second) {
          this.isLoading = true

          this.$services.user.doSetPassword(this.password.first).then(_ => {
            this.isOpen = false

            this.$store.commit('doSetTempPassword', false)
          }).catch(error => {
            this.$root.$emit('showError', 'An issue occurred setting your password, please immediately contact management!')
            alert(error)
          }).finally(_ => {
            this.isLoading = false
          })
        } else {
          this.password = {
            first: null,
            second: null
          }

          this.$root.$emit('showError', 'Passwords do not match!')
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
