<template>
  <div>
    <v-card>
      <v-card-title class="friendly-1">
        Welcome, {{ $store.state.user.fullName }}
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">Welcome</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">The Basics</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3">Dive in!</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" color="lighten-4" height="200px">
                <v-card-text>
                  You have been granted access to {{ companyName }} online platform, which allows you to manage courses, trips, and transactions on our website. This platform has been in development for several months with the goal of enhancing customer and employee engagement through user-friendly technology. Our website serves as more than just a platform for purchasing an open water course; it is our gateway to becoming the premier scuba shop in Phoenix, AZ. This guide will provide you with straightforward instructions on how to use this application to ensure that all online information is precise and current, reflecting the latest developments within our business.
                  <br>
                  <br>
                  <span class="font-weight-bold">Please be aware that all information on our platform is live and visible to the public. Any actions you take will be recorded and saved to your personal account, so it is important that you do not share your account with others. Your employment at {{ companyName }} grants you specific permissions, and certain actions may be restricted based on your role.</span>
                </v-card-text>
              </v-card>

              <v-btn color="primary" @click="step = 2">
                Continue
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="mb-12" color="lighten-4">
                <v-card-title>
                  Please watch this Video for The Basics
                </v-card-title>
                <v-card-text>
                  <iframe allow="fullscreen;" style="height: 200px; width: 100%; border: none;" class="rounded-lg" src="https://www.youtube.com/embed/K4TOrB7at0Y?autoplay=0&mute=0"></iframe>
                </v-card-text>
              </v-card>

              <v-btn color="primary" @click="step = 3">
                Continue
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-12" color="lighten-4" height="180px">
                <v-card-text>
                  Thank you for reviewing the employee welcome guide for our online system. If you wish to revisit the guide, please click on "Show Welcome Guide" in Support.
                  <br>
                  <br>
                  {{ $store.state.user.fullName }}, I am delighted to present this application to you. If you have any questions or concerns, please do not hesitate to contact me. My hope is that this application will attract more customers and foster growth within the scuba diving community.
                  <br>
                  <br>
                  Thank you,
                  <br>
                  Matthew Maggio @ Unixity LLC
                </v-card-text>
              </v-card>

              <v-btn color="primary" @click="doComplete">
                Dive in!
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'welcomeTutorial',
  data: () => ({
    step: 1
  }),
  methods: {
    doComplete () {
      this.step = 1
      this.$store.commit('doSetFirstSignIn', false)
      this.$emit('complete')
    }
  }
}
</script>

<style scoped>

</style>
